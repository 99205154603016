import React, { useEffect } from "react";

import "../../Assets/styles/slider.scss";
import slide1fisrt from "../../Assets/images/slide1fisrt.svg";
import slide1second from "../../Assets/images/slide1second.svg";
import slide1third from "../../Assets/images/slide1third.svg";
import slide2third from "../../Assets/images/slide2third.svg";
import slide3third from "../../Assets/images/slide3third.svg";
import slide4third from "../../Assets/images/slide4third.svg";
import slide5third from "../../Assets/images/slide5third.svg";

function ImageSlider() {
  useEffect(() => {
    document
      .querySelectorAll(".carousel-item.show-neighbors")
      .forEach(function (item) {
        let next = item.nextElementSibling;
        if (!next) {
          next = item.parentElement.firstElementChild;
        }
        let nextChild = next.children[0];
        item.appendChild(nextChild.cloneNode(true));
      });

    document
      .querySelectorAll(".carousel-item.show-neighbors")
      .forEach(function (item) {
        let prev = item.previousElementSibling;
        if (!prev) {
          prev = item.parentElement.lastElementChild;
        }
        let prevChild = prev.children[prev.children.length - 2];
        item.insertBefore(prevChild.cloneNode(true), item.firstElementChild);
      });
  }, []);

  return (
    <div>
      <div class="bd-example">
        <div
          id="carouselExampleCaptions"
          class="carousel slide multi-item-carousel"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleCaptions"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="4"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="5"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="6"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="7"></li>
          </ol>

          <div class="carousel-inner">
          <div class="carousel-item active">
              <div class="item__third">
                <div class="secondchild">
                  <div className="indiaJoy__slider__img">
                    <img src={slide1fisrt} alt="img" />
                  </div>
                  <div className="content">
                    <div>
                      <p className="quotes">{" "}&#8220;</p>
                      <p className="speakercontent">
                      The way the industry is evolving is quite rapid, especially with Artificial Intelligence. India has a huge talent base, and events like IndiaJoy provide invaluable opportunities for attendees to understand the latest and greatest happenings in the industry. This is absolutely the best place for that.
                      </p>
                      <div className="indiaJoy__desName">
                        <p className="speakername">
                        Vik Malyala
                        </p>
                        <p className="speakerdesignation">
                          President and Managing Director for Supermicro EMEA
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="item__third">
                <div class="secondchild">
                  <div className="indiaJoy__slider__img">
                    <img src={slide1second} alt="img" />
                  </div>
                  <div className="content">
                    <div>
                      <p className="quotes">{" "}&#8220;</p>
                      <p className="speakercontent">
                        I've been in the industry for 25 years, and IndiaJoy has
                        been very close to my heart. Everyone here is fantastic,
                        and the atmosphere is truly inspiring. I absolutely love
                        it. This event is a must-attend for anyone wanting to
                        network and be part of a vibrant, dynamic community
                      </p>
                      <div className="indiaJoy__desName">
                        <p className="speakername">Saraswathi “Vani” B</p>
                        <p className="speakerdesignation" >Creative Director - CEO of Dancing Atoms Studios</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="item__third">
                <div class="secondchild" style={{ width: "100%" }}>
                  <div className="indiaJoy__slider__img">
                    <img src={slide1third} alt="img" />
                  </div>
                  <div className="content">
                    <div>
                      <p className="quotes">
                        {" "}
                        &#8220;
                      </p>
                      <p className="speakercontent">
                      I have been following this event for many years, and it is one of the best I have seen for providing excellent exposure for young people
                      </p>
                      <div className="indiaJoy__desName">
                        <p className="speakername">
                          Srinivas Mohan
                        </p>
                        <p className="speakerdesignation">
                        Visual Effects Supervisor known for “RRR” & “ Bahubali ”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="item__third">
                <div class="secondchild" style={{ width: "100%" }}>
                  <div className="indiaJoy__slider__img">
                    <img src={slide2third} alt="img" />
                  </div>
                  <div className="content">
                    <div>
                      <p className="quotes" >
                        {" "}
                        &#8220;
                      </p>
                      <p className="speakercontent">
                      These kinds of events are very necessary in today’s times because people have lost interaction. While it's true that the internet provides a lot of knowledge, physical interaction, learning about new advancements, and meeting experts in person offer great opportunities for everyone
                      </p>
                      <div className="indiaJoy__desName">
                        <p className="speakername">
                          K.K. Senthil Kumar
                        </p>
                        <p className="speakerdesignation">Indian Cinematographer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="item__third">
                <div class="secondchild" style={{ width: "100%" }}>
                  <div className="indiaJoy__slider__img">
                    <img src={slide3third} alt="img" />
                  </div>
                  <div className="content">
                    <div>
                      <p
                        className="quotes"
                      >
                        {" "}
                        &#8220;
                      </p>
                      <p className="speakercontent">
                      I’m very happy to see many women professionals attending the event. I invite all women professionals and students to be a part of IndiaJoy, participate in mixers, and network so we can collectively support each other. This event is crucial for increasing women representation in the industry
                      </p>
                      <div className="indiaJoy__desName">
                        <p className="speakername">
                          Priyanka Ajit
                        </p>
                        <p className="speakerdesignation">
                          Manager of Women in Animation India Collective
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="item__third">
                <div class="secondchild" style={{ width: "100%" }}>
                  <div className="indiaJoy__slider__img">
                    <img src={slide4third} alt="img" />
                  </div>
                  <div className="content">
                    <div>
                      <p
                        className="quotes"
                      >
                        {" "}
                        &#8220;
                      </p>
                      <p className="speakercontent">
                      IndiaJoy is a fantastic event where the media and entertainment industry truly comes together. It's an honor to be part of such a collaborative and inspiring platform
                      </p>
                      <div className="indiaJoy__desName">
                        <p className="speakername">
                          Jamie Briens
                        </p>
                        <p className="speakerdesignation">
                          Director of Digital Transformation at Basilic Fly
                          Studio
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="item__third">
                <div class="secondchild" style={{ width: "100%" }}>
                  <div className="indiaJoy__slider__img">
                    <img src={slide5third} alt="img" />
                  </div>
                  <div className="content">
                    <div>
                      <p
                        className="quotes"
                      >
                        {" "}
                        &#8220;
                      </p>
                      <p className="speakercontent">
                      At IndiaJoy you see so much talent of Individual vfx artists who have not been associated with bigger studios who come up with truly outstanding work
                      </p>
                      <div className="indiaJoy__desName">
                        <p className="speakername">
                          Nag Ashwin
                        </p>
                        <p className="speakerdesignation">
                          Film Director and Screenwriter
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a
            class="carousel-control-prev"
            href="#carouselExampleCaptions"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleCaptions"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ImageSlider;

