import React from 'react';
import indiaJoyImg from '../../Assets/images/logo.svg';
import xLogo from '../../Assets/images/xLogo.svg';
import linkLogo from '../../Assets/images/linkd.svg';
import instaLogo from '../../Assets/images/insta.svg';
import metaLogo from '../../Assets/images/meta.svg';
import youtubeLogo from '../../Assets/images/youtube.svg';
import { Link } from 'react-router-dom';

const Menu = () => {
  const scrollToMainSection = () => {
    const sectionRef = document.getElementById('mainSection'); // Get the section element by its ID
    if (sectionRef) {
      window.scrollTo({
        top: sectionRef.offsetTop,
        behavior: 'smooth' // Smooth scrolling
      });
    }
  };

  return (
    <div className='indianJoy__wrapper'>
      <nav className="navbar navbar-expand-lg indiaJoy__container">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand" ><img src={indiaJoyImg} alt='indiaJoyImg' /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/about" className='nav-link'>About</Link>
              </li>
              <li className="nav-item">
                <Link className='nav-link' to="/" 
                onClick={scrollToMainSection}
                >Events</Link>
              </li>
              <li className="nav-item">
                <Link to="/pastEditions" className='nav-link'>PAST Editions</Link>
              </li>
              <li className="nav-item">
                <Link to="/speakers" className='nav-link'>Speakers</Link>
              </li>
              <li className="nav-item">
                <Link to="/agenda" className='nav-link'>Agenda</Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className='nav-link'>Contact</Link>
              </li>
            </ul>
            <button className="indiaJoy_register_btn">
              <Link to="/register" className='nav-link'>Register now</Link>
            </button>
            <div className='indiaJoy__rightPanel__container__menubar'>
              <div className='indiaJoy__rightPanel__menubar'>
                <div className='indiaJoy__rightPanel__section__menu'>
                  <a href="https://twitter.com/indiajoyin?lang=en" target="_blank" rel="noopener noreferrer">
                    <img src={xLogo} alt='xLogo' />
                  </a>
                </div>
                <div className='indiaJoy__rightPanel__section__menu'>
                  <a href="https://www.linkedin.com/company/indiajoy/" target="_blank" rel="noopener noreferrer">
                    <img src={linkLogo} alt='linkLogo' />
                  </a>
                </div>
                <div className='indiaJoy__rightPanel__section__menu'>
                  <a href="https://www.instagram.com/indiajoyfestival/" target="_blank" rel="noopener noreferrer">
                    <img src={instaLogo} alt='instaLogo' />
                  </a>
                </div>
                <div className='indiaJoy__rightPanel__section__menu'>
                  <a href="https://www.facebook.com/Indiajoy.in/" target="_blank" rel="noopener noreferrer">
                    <img src={metaLogo} alt='metaLogo' />
                  </a>
                </div>
                <div className='indiaJoy__rightPanel__section__menu'>
                  <a href="https://www.youtube.com/channel/UCe8cEAMgMZbqS2cf8t4_JQg" target="_blank" rel="noopener noreferrer">
                    <img src={youtubeLogo} alt='youtubeLogo' />
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </nav>
    </div>
  );
};

export default Menu;
