// utils.js
const API_BASE_URL = 'https://events.indiajoy.in';

// export const subscribeToNewsletter = async (email) => {
//     console.log('Preparing to send subscription request with email:', email);

//     try {
//         const response = await fetch(`${API_BASE_URL}/api/newsletters`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ email }),
//         });

//         console.log('Response status:', response.status);

//         if (!response.ok) {
//             const errorText = await response.text();
//             console.error('Network response was not ok:', errorText);
//             // throw new Error('Network response was not ok');
//             throw new Error(errorText);
//             // return errorText;
//         }

//         const data = await response.json();
//         console.log('Subscription response data:', data);
//         return data;
//     } catch (error) {
//         console.error('There was a problem with the fetch operation:', error);
//         throw error;
//     }
// };

// Function to send a subscription request to the newsletter
export const subscribeToNewsletter = async (email) => {
    console.log('Preparing to send subscription request with email:', email);

    try {
        const response = await fetch(`${API_BASE_URL}/api/newsletters`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        console.log('Response status:', response.status);

        if (!response.ok) {
            const errorText = await response.json(); // Parse the response as JSON
            console.error('Network response was not ok:', errorText);
            throw new Error(errorText.error); // Extract the error message
        }

        const data = await response.json();
        console.log('Subscription response data:', data);
        return data;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
};



export const sendConnectUserRequest = async (userData) => {
    console.log('Preparing to send connection request with data:', userData);

    try {
        const response = await fetch(`${API_BASE_URL}/api/connectusers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        console.log('Response status:', response.status);

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Network response was not ok:', errorText);
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Connection response data:', data);
        return data;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
};
